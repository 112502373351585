import { render, staticRenderFns } from "./DataPlatform.vue?vue&type=template&id=430a0af2&scoped=true&"
import script from "./DataPlatform.vue?vue&type=script&lang=js&"
export * from "./DataPlatform.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430a0af2",
  null
  
)

export default component.exports